.header {
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  z-index: 9;
  transition: all 0.5s ease-in-out;
}

.header.sticky {
  background: rgba(0, 0, 0, 0.85);
}

.header__logo {
  /* background: blueviolet; */
  width: 10%;
  position: absolute;
  left: 100px;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  object-fit: contain;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
}

.header.active .header__logo {
  width: 7%;
}

.header__logo h1 {
  font-size: 2rem;
}

.header__logo .logo {
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.header__nav {
  /* background-color: aqua; */
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  height: 10vh;
  color: aliceblue;
  border-bottom: 1px solid aliceblue;
  position: relative;
}

.menu {
  width: 40%;
  /* position: absolute;
  right: 0; */
  /* background: aquamarine; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  transition: all 1s ease;
  padding-left: 20px;
  margin-right: 10%;
}

.nav-menu {
  width: 100%;
}

.nav-menu .nav {
  display: inline-block;
  padding: 18px 16px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.nav:hover {
  color: #ef7f1a;
}

.menu-icon {
  margin-right: 50px;
  display: none;
}

/*-- humberger --*/
.hamburger1 {
  width: 30px;
  float: left;
  cursor: pointer;
  background: transparent;
}

.hamburger1 div {
  height: 3px;
  background: rgb(255, 255, 255);
  width: 24px;
  margin: 5px 0px;
  transition: all 0.5s;
  border-radius: 0px;
  text-align: center;
}

/* first */
.hamburger1:hover div:nth-child(1) {
  width: 12px;
  transition: all 0.5s;
}

.hamburger1:hover div:nth-child(2) {
  width: 18px;
  transition: all 0.5s;
}

.hamburger1:hover div:nth-child(3) {
  width: 30px;
  transition: all 0.5s;
}

.closed {
  position: relative;
  cursor: pointer;
}

.closed div {
  height: 3px;
  background: rgb(255, 255, 255);
  width: 24px;
  margin: 5px 0px;
  transition: all 0.5s;
  border-radius: 0px;
  text-align: center;
}

.closed div:nth-child(2) {
  display: none;
}
.closed div:nth-child(1) {
  transform: rotate(45deg);
  position: absolute;
  top: -5px;
}
.closed div:nth-child(3) {
  transform: rotate(-45deg);
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .header__logo {
    width: 20%;
    left: 60px;
  }

  .header.active .header__logo {
    width: 20%;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .menu.active {
    background: #242222;
    left: 0;
    transition: all 1s ease;
    z-index: 1;
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 90vh;
    position: absolute;
    top: 65px;
    left: -100%;
    opacity: 1;
    transition: all 1s ease;
  }

  .nav {
    display: table;
    color: #fff;
  }

  .nav:hover {
    color: #ef7f1a;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .header__logo {
    width: 20%;
    left: 50px;
  }

  .header.active .header__logo {
    width: 15%;
  }

  .header__logo h1 {
    font-size: 1.5rem;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .menu.active {
    background: #242222;
    left: 0;
    transition: all 1s ease;
    z-index: 1;
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 90vh;
    position: absolute;
    top: 65px;
    left: -100%;
    opacity: 1;
    transition: all 1s ease;
  }

  .nav {
    display: table;
    color: #fff;
  }

  .nav:hover {
    color: #ef7f1a;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .header__logo {
    /* background: blueviolet; */
    width: 23%;
    position: absolute;
    left: 50px;
  }

  .header.active .header__logo {
    width: 20%;
  }

  .header__logo h1 {
    font-size: 1.5rem;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    margin-left: 360px;
  }

  .menu.active {
    background: #242222;
    left: 0;
    transition: all 1s ease;
    z-index: 1;
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 1s ease;
  }

  .nav {
    display: table;
    color: #fff;
    text-align: center;
  }

  .nav:hover {
    color: #ef7f1a;
  }
}
