@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

.header {
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  z-index: 9;
  transition: all 0.5s ease-in-out;
}

.header.sticky {
  background: rgba(0, 0, 0, 0.85);
}

.header__logo {
  /* background: blueviolet; */
  width: 10%;
  position: absolute;
  left: 100px;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  object-fit: contain;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
}

.header.active .header__logo {
  width: 7%;
}

.header__logo h1 {
  font-size: 2rem;
}

.header__logo .logo {
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.header__nav {
  /* background-color: aqua; */
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  height: 10vh;
  color: aliceblue;
  border-bottom: 1px solid aliceblue;
  position: relative;
}

.menu {
  width: 40%;
  /* position: absolute;
  right: 0; */
  /* background: aquamarine; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  transition: all 1s ease;
  padding-left: 20px;
  margin-right: 10%;
}

.nav-menu {
  width: 100%;
}

.nav-menu .nav {
  display: inline-block;
  padding: 18px 16px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.nav:hover {
  color: #ef7f1a;
}

.menu-icon {
  margin-right: 50px;
  display: none;
}

/*-- humberger --*/
.hamburger1 {
  width: 30px;
  float: left;
  cursor: pointer;
  background: transparent;
}

.hamburger1 div {
  height: 3px;
  background: rgb(255, 255, 255);
  width: 24px;
  margin: 5px 0px;
  transition: all 0.5s;
  border-radius: 0px;
  text-align: center;
}

/* first */
.hamburger1:hover div:nth-child(1) {
  width: 12px;
  transition: all 0.5s;
}

.hamburger1:hover div:nth-child(2) {
  width: 18px;
  transition: all 0.5s;
}

.hamburger1:hover div:nth-child(3) {
  width: 30px;
  transition: all 0.5s;
}

.closed {
  position: relative;
  cursor: pointer;
}

.closed div {
  height: 3px;
  background: rgb(255, 255, 255);
  width: 24px;
  margin: 5px 0px;
  transition: all 0.5s;
  border-radius: 0px;
  text-align: center;
}

.closed div:nth-child(2) {
  display: none;
}
.closed div:nth-child(1) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  top: -5px;
}
.closed div:nth-child(3) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .header__logo {
    width: 20%;
    left: 60px;
  }

  .header.active .header__logo {
    width: 20%;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .menu.active {
    background: #242222;
    left: 0;
    transition: all 1s ease;
    z-index: 1;
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 90vh;
    position: absolute;
    top: 65px;
    left: -100%;
    opacity: 1;
    transition: all 1s ease;
  }

  .nav {
    display: table;
    color: #fff;
  }

  .nav:hover {
    color: #ef7f1a;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .header__logo {
    width: 20%;
    left: 50px;
  }

  .header.active .header__logo {
    width: 15%;
  }

  .header__logo h1 {
    font-size: 1.5rem;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .menu.active {
    background: #242222;
    left: 0;
    transition: all 1s ease;
    z-index: 1;
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 90vh;
    position: absolute;
    top: 65px;
    left: -100%;
    opacity: 1;
    transition: all 1s ease;
  }

  .nav {
    display: table;
    color: #fff;
  }

  .nav:hover {
    color: #ef7f1a;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .header__logo {
    /* background: blueviolet; */
    width: 23%;
    position: absolute;
    left: 50px;
  }

  .header.active .header__logo {
    width: 20%;
  }

  .header__logo h1 {
    font-size: 1.5rem;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    margin-left: 360px;
  }

  .menu.active {
    background: #242222;
    left: 0;
    transition: all 1s ease;
    z-index: 1;
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 1s ease;
  }

  .nav {
    display: table;
    color: #fff;
    text-align: center;
  }

  .nav:hover {
    color: #ef7f1a;
  }
}

.about {
  /* background: #333; */
  width: 100%;
  height: 100vh;
}

.about-header {
  width: 100%;
  height: 10vh;
  overflow: hidden;
  margin-bottom: 70px;
}

.about-header img {
  width: 100%;
}

.about-img {
  width: 100%;
  border-radius: 20px;
}

.about-img img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.about p {
  line-height: 1.7;
}

.about-info p {
  text-align: justify;
  line-height: 2.5;
}

/* .about-info p {
  text-align: justify;
} */

.about-why-us {
  background: rgb(238, 238, 238);
  padding: 20px 0;
  margin-bottom: 70px;
  margin-top: 50px;
}

.about-why-us-img {
  width: 100%;

  background: #ef7f1a;
  border-radius: 20px;
}

.about-why-us-img img {
  width: 100%;
  border-radius: 20px;
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
      ##Device = Low Resolution Tablets, Mobiles (Landscape)
      ##Screen = B/w 481px to 767px
    */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
      ##Device = Most of the Smartphones Mobiles (Portrait)
      ##Screen = B/w 320px to 479px
    */

@media (min-width: 320px) and (max-width: 480px) {
}

.footer {
  background: #000;
  width: 100%;
  /* height: 300px; */
}

.footer-abt {
  color: #fff;
  margin-bottom: 40px;
}

.footer-abt h2 {
  text-transform: capitalize;
  font-weight: 700;
}

.footer-abt p {
  font-size: small;
  line-height: 2;
}

.footer-abt .fab {
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
}

.footer-abt .social {
  color: #fff;
}

.footer-abt .social:hover {
  color: #ef7f1a;
}

.footer-therapy {
  color: #fff;
  margin-bottom: 40px;
}

.footer-therapy h4 {
  margin-bottom: 20px;
}

.footer-therapy .therapy {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: small;
  line-height: 2;
  text-transform: capitalize;
}

.footer-category {
  color: #fff;
  margin-bottom: 40px;
}

.footer-category h4 {
  margin-bottom: 20px;
}

.footer-category .category {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: small;
  line-height: 2;
  text-transform: capitalize;
}

.footer-therapy .therapy a,
.footer-category .category a {
  text-decoration: none;
  color: #fff;
}

.footer-therapy .therapy a i,
.footer-category .category a i {
  margin-right: 4px;
}

.footer-therapy .therapy a:hover,
.footer-category .category a:hover {
  color: #ef7f1a;
}

.footer-instagram {
  color: #fff;
}

.footer-instagram h4 {
  text-transform: capitalize;
}

.foot-copyright {
  width: 100%;
  /* height: 5vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 13px 10px 0px;
}

.foot-copyright p > a {
  color: #ef7f1a;
  text-decoration: none;
}

.admission {
  /* background: #333; */
  width: 100%;
  height: 100vh;
}

.admission-header {
  width: 100%;
  height: 10vh;
  overflow: hidden;
}

.admission-header img {
  width: 100%;
}

/* 
      ##Device = Tablets, Ipads (portrait)
      ##Screen = B/w 768px to 1024px
    */

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
        ##Device = Low Resolution Tablets, Mobiles (Landscape)
        ##Screen = B/w 481px to 767px
      */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
        ##Device = Most of the Smartphones Mobiles (Portrait)
        ##Screen = B/w 320px to 479px
      */

@media (min-width: 320px) and (max-width: 480px) {
}

.contact {
  /* background: #333; */
  width: 100%;
  height: 100vh;
}

.input-error {
  border: 0.5px solid #f43c3c;
}

.error {
  color: #f43c3c;
  font-size: 12px;
}

.contact-header {
  width: 100%;
  height: 10vh;
  overflow: hidden;
}

.contact-header img {
  width: 100%;
}

.info-location {
  display: flex;
  align-items: baseline;
  /* justify-content: center; */
}

.info-location .fa {
  font-size: xx-large;
  color: #ef7f1a;
  margin-right: 10px;
}

.info-address {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.info-phone {
  display: flex;
  /* justify-content: center; */
  align-items: baseline;
}

.fa {
  color: #ef7f1a;
}

.info-address .fa,
.info-phone .fa {
  font-size: xx-large;
  margin-right: 10px;
}

.info-phone .fab {
  font-size: xx-large;
  margin-right: 10px;
  color: green;
}

.info-email {
  display: flex;
  /* justify-content: center; */
  align-items: baseline;
}

.contact-location {
  /* background: gray; */
  width: 100%;
  /* height: 70vh; */
}

.contact-location h3 {
  margin-bottom: 30px;
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
}

.course {
  /* background: #333; */
  width: 100%;
  height: 100vh;
}

.course-header {
  width: 100%;
  height: 10vh;
  overflow: hidden;
}

.course-header img {
  width: 100%;
}

.course .accred p {
  font-size: 13px;
}

.course-duration ul li {
  text-transform: capitalize;
}

.course-duration ul span {
  font-size: 13px;
}

/* 
      ##Device = Tablets, Ipads (portrait)
      ##Screen = B/w 768px to 1024px
    */

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
        ##Device = Low Resolution Tablets, Mobiles (Landscape)
        ##Screen = B/w 481px to 767px
      */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
        ##Device = Most of the Smartphones Mobiles (Portrait)
        ##Screen = B/w 320px to 479px
      */

@media (min-width: 320px) and (max-width: 480px) {
}

.gallery {
  /* background: #333; */
  width: 100%;
  height: 100vh;
}

.gallery-header {
  width: 100%;
  height: 10vh;
  overflow: hidden;
}

.gallery-header img {
  width: 100%;
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
}

.imgview {
  padding: 0 12px;
  /* -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%; */
  -webkit-column-count: 5;
  column-count: 5;
}

.imgview .images {
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.imgview .images:hover {
  -webkit-filter: opacity(0.6);
          filter: opacity(0.6);
}

/* model */
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    -webkit-transform 0.5s ease-in-out;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  overflow: hidden;
  z-index: 10;
}

.model.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.model.open span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 30px;
  right: 30px;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.model.open span .fa {
  font-size: 2.5rem;
}

@media (max-width: 991px) {
  .imgview {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 480px) {
  .imgview {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    column-width: 100%;
    padding: 0 12px;
  }
}

.slider {
  width: 100%;
  height: 100vh;
  background: rgb(119, 119, 119);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

/* .carousel-item {
  object-fit: cover;
} */

.carousel-caption {
  top: 50%;
}

.carousel-indicators {
  top: 65%;
}

.carousel-indicators [data-bs-target] {
  background-color: #fff;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50%;
}

.carousel-indicators .active {
  opacity: 1;
  background-color: #ef7f1a;
}

.scrolldwn-btn {
  position: absolute;
  left: 50%;
  bottom: 10%;
  z-index: 4;
}

.scroll-arrow {
  width: 12px;
  height: 12px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-animation: arrow-wave 1s infinite;
          animation: arrow-wave 1s infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.scroll-arrow:nth-child(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.scroll-arrow:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.scroll-arrow:nth-child(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

@-webkit-keyframes arrow-wave {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes arrow-wave {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

/* @media (min-width: 1023px) {
  .scrolldwn-btn {
    bottom: 40%;
  }
} */

@media (min-width: 768px) and (max-width: 1024px) {
  .carousel-indicators {
    top: 70%;
  }

  .scrolldwn-btn {
    bottom: 60%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .carousel-caption {
    top: 30%;
  }

  .carousel-indicators {
    top: 70%;
  }

  .scrolldwn-btn {
    bottom: 59%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .carousel-caption {
    top: 55%;
  }

  .carousel-caption h3 {
    font-size: 16px;
    font-weight: 600;
  }

  .carousel-caption p {
    font-size: 12px;
  }

  .carousel-indicators {
    top: 85%;
  }

  .scrolldwn-btn {
    display: none;
  }

  .carousel-indicators [data-bs-target] {
    background-color: #fff;
    height: 10px;
    width: 10px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 50%;
  }

  .carousel-indicators .active {
    opacity: 1;
    background-color: #ef7f1a;
  }
}

.home {
  /* background: yellow; */
  width: 100%;
  height: 100vh;
}

.home__about {
  background: rgb(223, 222, 222);
}

.sub-title {
  color: #ef7f1a;
  text-transform: uppercase;
  font-family: "Poppins";
}

.hny-title {
  font-weight: 700;
  font-family: "Poppins";
}

.home__about .h-about p {
  margin: 0 auto;
  max-width: 700px;
  line-height: 1.8;
}

.btn-goshen {
  background: #ef7f1a;
  color: #fff;
}

.card .fa-clock {
  color: #fff;
}

.card .fa-clock,
.card .fa-cut {
  font-size: 7rem;
}

.card-group .card:nth-child(1) {
  background: #ef7f1a;
}

.card-title.h5 {
  font-size: 25px;
  color: #ef7f1a;
}

.white {
  color: #fff;
}

.card-text {
  font-size: 15px;
  margin-bottom: 2.5rem;
  line-height: 2;
}

.card-text .time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.card-text .time h4 {
  font-size: 18px;
}

.card-text span {
  font-size: 12px;
}

.home-service {
  width: 100%;
  /* height: 100vh; */
}

.h-service p {
  /* max-width: 600px; */
  line-height: 2;
}

.service-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.service-list .fa-check {
  color: #ef7f1a;
  margin-right: 5px;
}

.service-list-left,
.service-list-right {
  text-transform: capitalize;
  font-weight: 600;
}

.home-course,
.home-course2 {
  display: flex;
}

.home-course-img,
.home-course-img2 {
  /* background: turquoise; */
  width: 50%;
  /* height: 200px; */
  overflow: hidden;
}

.home-course-img img,
.home-course-img2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s; /* Animation */
}

.home-course-img:hover img,
.home-course-img2:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.home-course-detial,
.home-course-detial2 {
  background: #333;
  width: 50%;
  /* height: 60vh; */
  color: #fff;
  padding: 10rem 4rem;
}

.home-course2 h2,
.home-course h2 {
  font-weight: 700;
}

.home-gallery {
  padding: 6rem 2rem;
}

.home-testimonial {
  padding: 6rem 2rem;
  background: rgb(236, 236, 236);
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .home-course,
  .home-course2 {
    flex-direction: column;
  }

  .home-course-img,
  .home-course-img2 {
    width: 100%;
  }

  .home-course-detial,
  .home-course-detial2 {
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .home-course2:last-child {
    flex-direction: column-reverse;
    order: 1;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .home-course,
  .home-course2 {
    flex-direction: column;
  }

  .home-course-img,
  .home-course-img2 {
    width: 100%;
  }

  .home-course-detial,
  .home-course-detial2 {
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .home-course2:last-child {
    flex-direction: column-reverse;
    order: 1;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .home-course,
  .home-course2 {
    flex-direction: column;
  }

  .home-course-img,
  .home-course-img2 {
    width: 100%;
  }

  .home-course2 {
    flex-direction: column-reverse;
    order: 1;
  }

  .home-course-detial,
  .home-course-detial2 {
    width: 100%;
    padding-top: 4rem;
  }
}

.gslider-wrapper {
  position: relative;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}

.gslider-wrapper:active,
.gslider-wrapper:hover,
.gslider-wrapper:focus {
  outline: none;
  border: none;
}

.gslider-card {
  width: 100%;
  height: 450px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.gslider-card .gslider-card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #000;
  transition: 0.5s;
}

.gslider-card .gslider-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gslider-card .gslider-content {
  position: absolute;
  z-index: 3;
  bottom: 0;
  width: 100%;
  height: 4.3rem;
}

.gslider-content .gslider-content-info {
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gslider-card:hover .gslider-content .gslider-content-info {
  background: #ef7f1a;
  color: #fff;
  transition: 0.5s ease;
}

.gslider-card:hover .gslider-content .gslider-content-info .btn-goshen {
  background: #fff;
  color: #ef7f1a;
  transition: 0.5s ease;
}

.gslider-content .gslider-content-info h3 {
  font-size: 20px;
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  /* .gslider-card .gslider-content {
    height: 6rem;
  }

  .gslider-content .gslider-content-info {
    flex-direction: column;
    justify-content: space-between;
  } */
}

.testimonial-wrapper {
  position: relative;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}

.testimonial-card {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.testimonial-card .testimonial-content {
  width: 100%;
}

.testimonial-content .testimonial-content-info span .fa {
  color: #ef7f1a;
  font-size: 42px;
  opacity: 0.3;
}

.testimonial-content .testimonial-content-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.testimonial-content .testimonial-content-info span {
  margin-right: 10px;
}

.testimonial-content .testimonial-content-info h3 {
  font-size: 20px;
  color: #ef7f1a;
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .testimonial-content .testimonial-content-info p {
    font-size: 12px;
  }
}

.service {
  /* background: #333; */
  width: 100%;
  height: 100vh;
}

.service-header {
  width: 100%;
  height: 10vh;
  overflow: hidden;
}

.service-header img {
  width: 100%;
}

/* 
        ##Device = Tablets, Ipads (portrait)
        ##Screen = B/w 768px to 1024px
      */

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
          ##Device = Low Resolution Tablets, Mobiles (Landscape)
          ##Screen = B/w 481px to 767px
        */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
          ##Device = Most of the Smartphones Mobiles (Portrait)
          ##Screen = B/w 320px to 479px
        */

@media (min-width: 320px) and (max-width: 480px) {
}

/* .App {
  text-align: center;
} */

.notfound {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notfound h1 {
  font-size: 8rem;
  font-weight: 900;
  color: #ef7f1a;
}

.notfound h3 {
  font-weight: 600;
  text-transform: uppercase;
}

.notfound p {
  width: 500px;
  text-align: center;
}

.notfound a {
  text-decoration: none;
  background: #ef7f1a;
  color: #fff;
  padding: 12px 16px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 1px 1px 5px gray;
}

