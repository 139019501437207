.about {
  /* background: #333; */
  width: 100%;
  height: 100vh;
}

.about-header {
  width: 100%;
  height: 10vh;
  overflow: hidden;
  margin-bottom: 70px;
}

.about-header img {
  width: 100%;
}

.about-img {
  width: 100%;
  border-radius: 20px;
}

.about-img img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.about p {
  line-height: 1.7;
}

.about-info p {
  text-align: justify;
  line-height: 2.5;
}

/* .about-info p {
  text-align: justify;
} */

.about-why-us {
  background: rgb(238, 238, 238);
  padding: 20px 0;
  margin-bottom: 70px;
  margin-top: 50px;
}

.about-why-us-img {
  width: 100%;

  background: #ef7f1a;
  border-radius: 20px;
}

.about-why-us-img img {
  width: 100%;
  border-radius: 20px;
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
      ##Device = Low Resolution Tablets, Mobiles (Landscape)
      ##Screen = B/w 481px to 767px
    */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
      ##Device = Most of the Smartphones Mobiles (Portrait)
      ##Screen = B/w 320px to 479px
    */

@media (min-width: 320px) and (max-width: 480px) {
}
