/* .App {
  text-align: center;
} */

.notfound {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notfound h1 {
  font-size: 8rem;
  font-weight: 900;
  color: #ef7f1a;
}

.notfound h3 {
  font-weight: 600;
  text-transform: uppercase;
}

.notfound p {
  width: 500px;
  text-align: center;
}

.notfound a {
  text-decoration: none;
  background: #ef7f1a;
  color: #fff;
  padding: 12px 16px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 1px 1px 5px gray;
}
