.imgview {
  padding: 0 12px;
  /* -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%; */
  -webkit-column-count: 5;
  -moz-column-count: 5;
  column-count: 5;
}

.imgview .images {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.imgview .images:hover {
  filter: opacity(0.6);
}

/* model */
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 10;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.model.open span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 30px;
  right: 30px;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.model.open span .fa {
  font-size: 2.5rem;
}

@media (max-width: 991px) {
  .imgview {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 480px) {
  .imgview {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
    padding: 0 12px;
  }
}
