.slider {
  width: 100%;
  height: 100vh;
  background: rgb(119, 119, 119);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

/* .carousel-item {
  object-fit: cover;
} */

.carousel-caption {
  top: 50%;
}

.carousel-indicators {
  top: 65%;
}

.carousel-indicators [data-bs-target] {
  background-color: #fff;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50%;
}

.carousel-indicators .active {
  opacity: 1;
  background-color: #ef7f1a;
}

.scrolldwn-btn {
  position: absolute;
  left: 50%;
  bottom: 10%;
  z-index: 4;
}

.scroll-arrow {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  animation: arrow-wave 1s infinite;
  animation-direction: alternate;
}

.scroll-arrow:nth-child(1) {
  animation-delay: 0.1s;
}

.scroll-arrow:nth-child(2) {
  animation-delay: 0.2s;
}

.scroll-arrow:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes arrow-wave {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

/* @media (min-width: 1023px) {
  .scrolldwn-btn {
    bottom: 40%;
  }
} */

@media (min-width: 768px) and (max-width: 1024px) {
  .carousel-indicators {
    top: 70%;
  }

  .scrolldwn-btn {
    bottom: 60%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .carousel-caption {
    top: 30%;
  }

  .carousel-indicators {
    top: 70%;
  }

  .scrolldwn-btn {
    bottom: 59%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .carousel-caption {
    top: 55%;
  }

  .carousel-caption h3 {
    font-size: 16px;
    font-weight: 600;
  }

  .carousel-caption p {
    font-size: 12px;
  }

  .carousel-indicators {
    top: 85%;
  }

  .scrolldwn-btn {
    display: none;
  }

  .carousel-indicators [data-bs-target] {
    background-color: #fff;
    height: 10px;
    width: 10px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 50%;
  }

  .carousel-indicators .active {
    opacity: 1;
    background-color: #ef7f1a;
  }
}
