.home {
  /* background: yellow; */
  width: 100%;
  height: 100vh;
}

.home__about {
  background: rgb(223, 222, 222);
}

.sub-title {
  color: #ef7f1a;
  text-transform: uppercase;
  font-family: "Poppins";
}

.hny-title {
  font-weight: 700;
  font-family: "Poppins";
}

.home__about .h-about p {
  margin: 0 auto;
  max-width: 700px;
  line-height: 1.8;
}

.btn-goshen {
  background: #ef7f1a;
  color: #fff;
}

.card .fa-clock {
  color: #fff;
}

.card .fa-clock,
.card .fa-cut {
  font-size: 7rem;
}

.card-group .card:nth-child(1) {
  background: #ef7f1a;
}

.card-title.h5 {
  font-size: 25px;
  color: #ef7f1a;
}

.white {
  color: #fff;
}

.card-text {
  font-size: 15px;
  margin-bottom: 2.5rem;
  line-height: 2;
}

.card-text .time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.card-text .time h4 {
  font-size: 18px;
}

.card-text span {
  font-size: 12px;
}

.home-service {
  width: 100%;
  /* height: 100vh; */
}

.h-service p {
  /* max-width: 600px; */
  line-height: 2;
}

.service-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.service-list .fa-check {
  color: #ef7f1a;
  margin-right: 5px;
}

.service-list-left,
.service-list-right {
  text-transform: capitalize;
  font-weight: 600;
}

.home-course,
.home-course2 {
  display: flex;
}

.home-course-img,
.home-course-img2 {
  /* background: turquoise; */
  width: 50%;
  /* height: 200px; */
  overflow: hidden;
}

.home-course-img img,
.home-course-img2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s; /* Animation */
}

.home-course-img:hover img,
.home-course-img2:hover img {
  transform: scale(1.1);
}

.home-course-detial,
.home-course-detial2 {
  background: #333;
  width: 50%;
  /* height: 60vh; */
  color: #fff;
  padding: 10rem 4rem;
}

.home-course2 h2,
.home-course h2 {
  font-weight: 700;
}

.home-gallery {
  padding: 6rem 2rem;
}

.home-testimonial {
  padding: 6rem 2rem;
  background: rgb(236, 236, 236);
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .home-course,
  .home-course2 {
    flex-direction: column;
  }

  .home-course-img,
  .home-course-img2 {
    width: 100%;
  }

  .home-course-detial,
  .home-course-detial2 {
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .home-course2:last-child {
    flex-direction: column-reverse;
    order: 1;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .home-course,
  .home-course2 {
    flex-direction: column;
  }

  .home-course-img,
  .home-course-img2 {
    width: 100%;
  }

  .home-course-detial,
  .home-course-detial2 {
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .home-course2:last-child {
    flex-direction: column-reverse;
    order: 1;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .home-course,
  .home-course2 {
    flex-direction: column;
  }

  .home-course-img,
  .home-course-img2 {
    width: 100%;
  }

  .home-course2 {
    flex-direction: column-reverse;
    order: 1;
  }

  .home-course-detial,
  .home-course-detial2 {
    width: 100%;
    padding-top: 4rem;
  }
}
