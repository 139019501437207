.testimonial-wrapper {
  position: relative;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}

.testimonial-card {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.testimonial-card .testimonial-content {
  width: 100%;
}

.testimonial-content .testimonial-content-info span .fa {
  color: #ef7f1a;
  font-size: 42px;
  opacity: 0.3;
}

.testimonial-content .testimonial-content-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.testimonial-content .testimonial-content-info span {
  margin-right: 10px;
}

.testimonial-content .testimonial-content-info h3 {
  font-size: 20px;
  color: #ef7f1a;
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .testimonial-content .testimonial-content-info p {
    font-size: 12px;
  }
}
