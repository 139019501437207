.footer {
  background: #000;
  width: 100%;
  /* height: 300px; */
}

.footer-abt {
  color: #fff;
  margin-bottom: 40px;
}

.footer-abt h2 {
  text-transform: capitalize;
  font-weight: 700;
}

.footer-abt p {
  font-size: small;
  line-height: 2;
}

.footer-abt .fab {
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
}

.footer-abt .social {
  color: #fff;
}

.footer-abt .social:hover {
  color: #ef7f1a;
}

.footer-therapy {
  color: #fff;
  margin-bottom: 40px;
}

.footer-therapy h4 {
  margin-bottom: 20px;
}

.footer-therapy .therapy {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: small;
  line-height: 2;
  text-transform: capitalize;
}

.footer-category {
  color: #fff;
  margin-bottom: 40px;
}

.footer-category h4 {
  margin-bottom: 20px;
}

.footer-category .category {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: small;
  line-height: 2;
  text-transform: capitalize;
}

.footer-therapy .therapy a,
.footer-category .category a {
  text-decoration: none;
  color: #fff;
}

.footer-therapy .therapy a i,
.footer-category .category a i {
  margin-right: 4px;
}

.footer-therapy .therapy a:hover,
.footer-category .category a:hover {
  color: #ef7f1a;
}

.footer-instagram {
  color: #fff;
}

.footer-instagram h4 {
  text-transform: capitalize;
}

.foot-copyright {
  width: 100%;
  /* height: 5vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 13px 10px 0px;
}

.foot-copyright p > a {
  color: #ef7f1a;
  text-decoration: none;
}
