.service {
  /* background: #333; */
  width: 100%;
  height: 100vh;
}

.service-header {
  width: 100%;
  height: 10vh;
  overflow: hidden;
}

.service-header img {
  width: 100%;
}

/* 
        ##Device = Tablets, Ipads (portrait)
        ##Screen = B/w 768px to 1024px
      */

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
          ##Device = Low Resolution Tablets, Mobiles (Landscape)
          ##Screen = B/w 481px to 767px
        */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
          ##Device = Most of the Smartphones Mobiles (Portrait)
          ##Screen = B/w 320px to 479px
        */

@media (min-width: 320px) and (max-width: 480px) {
}
