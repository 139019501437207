.gslider-wrapper {
  position: relative;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}

.gslider-wrapper:active,
.gslider-wrapper:hover,
.gslider-wrapper:focus {
  outline: none;
  border: none;
}

.gslider-card {
  width: 100%;
  height: 450px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.gslider-card .gslider-card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #000;
  transition: 0.5s;
}

.gslider-card .gslider-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gslider-card .gslider-content {
  position: absolute;
  z-index: 3;
  bottom: 0;
  width: 100%;
  height: 4.3rem;
}

.gslider-content .gslider-content-info {
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gslider-card:hover .gslider-content .gslider-content-info {
  background: #ef7f1a;
  color: #fff;
  transition: 0.5s ease;
}

.gslider-card:hover .gslider-content .gslider-content-info .btn-goshen {
  background: #fff;
  color: #ef7f1a;
  transition: 0.5s ease;
}

.gslider-content .gslider-content-info h3 {
  font-size: 20px;
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  /* .gslider-card .gslider-content {
    height: 6rem;
  }

  .gslider-content .gslider-content-info {
    flex-direction: column;
    justify-content: space-between;
  } */
}
