.contact {
  /* background: #333; */
  width: 100%;
  height: 100vh;
}

.input-error {
  border: 0.5px solid #f43c3c;
}

.error {
  color: #f43c3c;
  font-size: 12px;
}

.contact-header {
  width: 100%;
  height: 10vh;
  overflow: hidden;
}

.contact-header img {
  width: 100%;
}

.info-location {
  display: flex;
  align-items: baseline;
  /* justify-content: center; */
}

.info-location .fa {
  font-size: xx-large;
  color: #ef7f1a;
  margin-right: 10px;
}

.info-address {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.info-phone {
  display: flex;
  /* justify-content: center; */
  align-items: baseline;
}

.fa {
  color: #ef7f1a;
}

.info-address .fa,
.info-phone .fa {
  font-size: xx-large;
  margin-right: 10px;
}

.info-phone .fab {
  font-size: xx-large;
  margin-right: 10px;
  color: green;
}

.info-email {
  display: flex;
  /* justify-content: center; */
  align-items: baseline;
}

.contact-location {
  /* background: gray; */
  width: 100%;
  /* height: 70vh; */
}

.contact-location h3 {
  margin-bottom: 30px;
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
}
